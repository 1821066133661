* {
  margin:0;
  padding:0;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("fonts/Linotype - HelveticaNeueLTStd-Md.ttf")
}

@font-face {
  font-family: "Zuume SemiBold Italic";
  src: url("fonts/Zuume-SemiBoldItalic.ttf")
}

@font-face {
  font-family: "VCR OSD Mono";
  src: url("fonts/VCR_OSD_MONO_1.001 2.ttf")
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 100%; }
}

a:visited{
  color: black;
}

a.stroke-on-hover:hover {
  -webkit-text-stroke-width: 0.75px;
  -webkit-text-stroke-color: black;
  color: transparent;
}

a{
  color: black;
}

a.black-link{
  color: black;
  text-decoration: underline;
}

a.black-link:hover{
  -webkit-text-stroke-width: 0.75px;
  -webkit-text-stroke-color: black;
  color: transparent;
}

.stroke {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color:black;
  color: transparent;
}

videox {
  object-fit: cover;
  clip-path: url(#dndtf-handdrawn-svg-clip-path);
  /* clip-path: polygon(100% 0, 88% 63%, 58% 94%, 46% 94%, 13% 63%, 0 0); */
}

div #svg-clipped-animation{
  /* object-fit: cover; */
  clip-path: url(#dndtf-handdrawn-svg-clip-path);
  /* clip-path: polygon(100% 0, 88% 63%, 58% 94%, 46% 94%, 13% 63%, 0 0); */
}

body {
  background-color: 'white';
}

.footer {
  /* color: var(--color-text, #000); */
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
	padding: 6px 13px;
	display: block;
	/* pointer-events: none!important; */
	z-index: 100;
  transition: color .5s;
  text-align: right;
}

.footer-text {
	color: #000;
  font-family: Antique,Helvetica,Arial,sans-serif;
  font-weight: 400;
  font-size: 10px;
  padding-left: 5px;
}

.app-warn-h1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  font-family: 'Helvetica Neue LT Std';
  font-size: 1em;
  /* display: inline-block; */
}

.app-warn-link-h2 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  font-family: 'Zuume SemiBold Italic';
  font-size: 5em;
  /* display: inline-block; */
}